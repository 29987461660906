@import 'settings.scss';

.contact-us {
    text-align: center;
    color: $white;
    margin-top: 132px;

    .contact-us-content {
        padding: 0 32px;
    }

    h2 {
        font-size: 16px;
        color: $white;
    }

    @media (min-width: $mobileBreakpoint) {
        .contact-us-content {
            padding: 12px;
        }

        h2 {
            font-size: 24px !important;
        }    
    }
}
