// h1
.scheduler-form-header {
    font-family: "Chivo", sans-serif;
    text-transform: none;
    font-size: 22px;
    padding: 18px 32px 18px 0;
    margin: 0;
    position: relative;
    text-align: left;

    .close-icon {
        position: absolute;
        top: 18px;
        right: 0;
        padding: 3px 7px;
        font-size: 20px;
        cursor: pointer;
        border: none;
        outline:none;
    }
}