@import 'settings.scss';

.scheduler-container-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $teal;
}

.scheduler-container {
    color: $white;
    font-family: "Chivo", sans-serif;
    text-align: center;

    .main-content {
        position: relative;
    }
}
