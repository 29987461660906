@import 'settings.scss';

.footer {
    padding: 10px 0 15px 0;
    text-align: center;
    color: $white;

    .copyright {
        margin-top: 20px;
    }

    @media (min-width: $mobileBreakpoint) {
        padding: 60px 0;
    }
}