@import 'settings';

.scheduler-form {
    position: relative;
    z-index: 1;
    padding: 0 12px;

    .spacer {
        margin-bottom: 16px;
    }

    .end-spacer {
        margin-bottom: 48px;
    }

    .empty-spacer {
        width: 100%;
        height: 160px;
    }

    .placeholder {
        display: block;
        font-size: 18px;
        width: 100%;
        margin-bottom: 6px;
    }

    .secondary-placeholder {
        display: block;
        font-size: 14px;
        margin-top: -6px;
        margin-bottom: 12px;
    }

    .error-message {
        margin-top: 10px;
        color: $red;
    }

    .react-datepicker {
        font-family: 'Chivo', sans-serif;
    }

    .field-unset {
        color: $grey3;
    }

    .form-note {
        margin-top: 30px;
    }


    .event-header {
        margin-top: 36px;
        margin-bottom: -16px;
    }

    .event-header-message {
        margin-top: -12px;
        font-size: 14px;
    }

    input {
        box-sizing: border-box;
    }

    input[type=text],
    input[type=email],
    input[type=date],
    input[type=time],
    input[type=tel],
    input.text-input,
    input.email-input,
    select {
        font-family: "Chivo", sans-serif;
        outline: 0;
        background-color: $grey1;
        border: 1px solid $grey2;
        border-radius: 4px;
        padding: 4px 12px;
        font-size: 14px;
        height: 36px;
    }
    input[type=text],
    input.text-input {
        width: 100%;
        margin-bottom: 12px;
    }
    input[type=date],
    input[type=time],
    select {
        min-width: 260px;
        margin-bottom: 12px;
    }
    select:focus {
        outline: 1px solid $teal;
    }

    .checkbox-container {
        display: flex;
        margin-bottom: 8px;
    }
    .checkbox-description {
        line-height: 20px;
    }
    input[type=checkbox] {
        cursor: pointer;
        margin: 0 6px;
        height: 20px;
        width: 20px;
    }

    .invalid {
        border: 1px solid $red !important;
    }

    .submit-button-container {
        margin-top: 16px;
        width: 100%;
    }

    .submit-button {
        margin-top: 24px;
        margin-right: 12px;
    }

    .submit-button.disabled {
        cursor: auto;
        color: $grey3;
    }

    .field-container {
        position: relative;
        width: 100%;
    }

    .error-notification {
        background: $grey1;
        border-radius: 4px;
        border: 1px solid $red;
        color: $red;
        margin-top: 40px;
        padding: 12px;

        h4 {
            font-family: "Chivo", sans-serif;
            font-weight: normal;
            margin: 12px 0;
        }

        ul {
            margin: 12px 0;
        }

        li {
            font-size: 14px;
            margin-bottom: 4px;
        }
    }

    @media (min-width: $mobileBreakpoint) {
        max-width: 70%;
        margin: 0 auto;
        padding: 0;

        .field-container {
            display: flex;
            flex-direction: row;

            .name-container-row {
                width: 50%;
            }

            .name-container-row:first-child,
            .time-container-row,
            .select-container {
                padding-right: 12px;
            }
        }
    }
}
