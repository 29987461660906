@import 'settings';

.scheduler-container {
    .scheduler-form-container {
        text-align: left;
        max-height: 100vh;
        width: 100%;
        margin-bottom: 0;
    }

    .scheduler-form-container::-webkit-scrollbar {
        display: none;
        width: 0;
        background: transparent;
    }

    .subsection-header {
        margin-top: 64px;
    }
}

// hide scrollbar
.scheduler-form::-webkit-scrollbar {
    display: none;
}
