@import 'settings.scss';
@import 'index.scss';

.events-table-container {
    width: 100%;
    height: 400px;
    background-color: $white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: $grey6;
    display: block;
    position: relative;

    .events-table {
        border: 0;
        width: 100%;
        height: 100%;
        border-top-left-radius: 4px;

        th {
            //z-index: 2;
            border: none;
        }

        tr.tr-spacer {
            width: 100%;
            height: 160px;
            background-color: $white;

            td {
                border: none;
            }
        }

        tr.tr-spacer-bottom {
            height: 400px;
        }

        p.loading-more {
            position: absolute;
        }

        p.loading-more-top {
            left: 0;
            top: 44px;
        }

        p.loading-more-bottom {
            bottom: 0;
            left: 0;
        }

        td:nth-child(2) {
            width: 100%;
        }

        td:nth-child(3) {
            min-width: 60px;
        }

        td:nth-child(4) {
            min-width: 200px;
        }

        td:last-child {
            padding-right: 12px;
        }
    }

    p {
        color: $grey5;
        margin: 12px 8px;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        border: 2px solid transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    .events-dates {
        margin: 0 0 22px 0;
        background-color: $white;
        padding: 8px 0 2px 0;
        text-align: center;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .ready-circle {
        margin: 2.5px auto;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $grey2;
    }

    .ready-circle-red {
        background-color: red;
    }

    .ready-circle-green {
        background-color: green;
    }

    .ready-circle-yellow {
        background-color: yellow;
    }

    //@media (min-width: $mobileBreakpoint) {
    //    width: 80%;
    //    margin: 0 auto 32px auto;
    //}

}
