@import 'settings.scss';
@import 'index.scss';

.timepicker-ui-wrapper {
    box-shadow: none;
    height: 520px;

    .timepicker-ui-select-time {
        font-family: $chivo;
        font-size: 14px;
        color: $black;
    }

    .timepicker-ui-header {
        padding-top: 48px;
        padding-bottom: 24px;
    }

    .timepicker-ui-hour,
    .timepicker-ui-minutes,
    .timepicker-ui-hour.mobile,
    .timepicker-ui-minutes.mobile {
        background-color: $grey1;
        border: 1px solid $grey2;
        font-family: $chivo;
        font-size: 32px;
        height: 58px;
        width: 64px;
    }

    .timepicker-ui-dots,
    .timepicker-ui-dots.mobile {
        font-family: $chivo;
        font-size: 32px;
    }

    .timepicker-ui-hour.mobile,
    .timepicker-ui-minutes.mobile {
        height: 48px;
    }

    .timepicker-ui-hour-text,
    .timepicker-ui-minute-text,
    .timepicker-ui-hour-text.mobile,
    .timepicker-ui-minute-text.mobile {
        font-family: $chivo;
    }

    .timepicker-ui-am,
    .timepicker-ui-pm,
    .timepicker-ui-am.mobile,
    .timepicker-ui-pm.mobile,
    .timepicker-ui-clock-face {
        font-family: $chivo;
        background-color: $grey1;
        border: 1px solid $grey2;
    }

    .timepicker-ui-hour:hover,
    .timepicker-ui-hour.active,
    .timepicker-ui-minutes:hover,
    .timepicker-ui-minutes.active,
    .timepicker-ui-hour.mobile:hover,
    .timepicker-ui-hour.mobile.active,
    .timepicker-ui-minutes.mobile:hover,
    .timepicker-ui-minutes.mobile.active,
    .timepicker-ui-am:hover,
    .timepicker-ui-am.active,
    .timepicker-ui-pm:hover,
    .timepicker-ui-pm.active,
    .timepicker-ui-am.mobile:hover,
    .timepicker-ui-am.mobile.active,
    .timepicker-ui-pm.mobile:hover,
    .timepicker-ui-pm.mobile.active {
        background-color: $blue2;
        color: $white;
        border: 1px solid $blue2;
    }

    .timepicker-ui-circle-hand,
    .timepicker-ui-clock-hand,
    .timepicker-ui-dot {
        background-color: $blue2;
    }

    .timepicker-ui-type-mode {
        font-family: $chivo;
        width: 48px;
    }

    .timepicker-ui-value-tips {
        font-family: $chivo;
    }

    .timepicker-ui-footer {
        height: 96px;
    }

    .timepicker-ui-wrapper-btn {
        bottom: -24px;
        margin-bottom: 30px;
    }

    .timepicker-ui-cancel-btn,
    .timepicker-ui-ok-btn,
    .timepicker-ui-cancel-btn.mobile,
    .timepicker-ui-ok.btn-mobile {
        @include viewlogies-button;

        border-radius: 0;
        display: block;
        width: unset;
    }
}



