@import 'settings.scss';

.dashboard-container {
    background-color: $teal;
    min-height: calc(100vh - 24px);
    padding: 12px;

    .dashboard {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 12px;
    }

    .header {
        margin-top: 0;
    }

    .subheader-container, .subfooter-container {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
    }

    .subheader-item {
        width: 100%;
        background-color: $white;
        border-radius: 4px;
        margin-bottom: 20px;
        overflow: hidden;

        h2 {
            margin: 12px;
        }

        p {
            font-size: 18px;
        }

        .subheader-button {
            width: 100%;
            height: 100%;
            border: none;
            background: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }

        .subheader-button:hover {
            background-color: $grey1;
        }
    }

    .subfooter-container {
        margin-top: 66px;
        margin-bottom: 20px;
    }

    .subfooter-item {
        margin-top: 20px;
    }

    @media (min-width: $mobileBreakpoint) {
        .subheader-item {
            min-height: 140px;
        }

        .subheader-item:not(:last-child),
        .subfooter-item:not(:last-child) {
            margin-right: 20px;
        }

        .subheader-container, .subfooter-container {
            flex-wrap: unset;
        }

        .subfooter-container {
            margin-top: 51px;
        }
    }
}