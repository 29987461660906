@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:300&display=swap');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:700&display=swap');
@import 'settings.scss';

body {
    margin: 0;
    font-family: $chivo;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: $black;
}

h1 {
    font-family: $comfortaa;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 18px;
}
h2 {
    font-family: $chivo;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: $teal;
}
h3 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: .2em;
}
p {
    font-size: 13px;
    margin-top: 15px;
}

hr {
    border: 0;
    height: 1px;
    background: $grey2;
    margin: 20px 0;
}

@media (min-width: $mobileBreakpoint) {
    h1 {
       font-size: 24px;
    }
}

// global classes
.main-content {
    padding: 0;
}

.centered {
    text-align: center;
}

.emphasized {
    font-family: $chivo;
    font-style: italic;
}

.hidden {
    display: none !important;
}

.submit-button {
    font-family: "Chivo", sans-serif;
    text-transform: uppercase;
    outline: 0;
    border: none;
    border-radius: 4px;
    padding: 12px 45px;
    background-color: $white;
    color: $black;
    font-size: 14px;
    cursor: pointer;
}

@media (min-width: $mobileBreakpoint) {
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 28px;
    }
    h3 {
        font-size: 16px;
    }
}

select:disabled, input:disabled, a.disabled {
    opacity: .9;
    color: $grey4;
}
a.disabled {
    pointer-events: none;
}

table {
    display: block;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 600px;
    white-space: nowrap;
    border-collapse: collapse;
}

th {
    box-shadow: inset 0 -1px 0 #ccc;
    border-bottom: none;
    background-color: $white;
    line-height: 24px;
    font-family: "Chivo", sans-serif;
    font-size: 14px;
    color: $teal;
    padding: 10px 8px;
    position: sticky;
    top: 0;
}
tr {
    font-size: 14px;
}
td,
th {
    border-right: 1px solid #ccc;
    overflow: hidden;
    text-align: left;
    vertical-align: top;
    max-width: 200px;
}
td {
    padding: 8px;
}
td:last-child,
th:last-child {
    border-right: none;
}
thead {
}
/* tr:last-child {
    border-bottom: none;
} */

tbody tr:nth-child(even) {
    background-color: #dddddd;
}

// BUTTONS

@mixin viewlogies-button {
    padding: 15px;
    font-family: 'Chivo', sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #ffffff;
    border: 2px solid $blue2;
    color: $blue2;
    font-size: 15px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    transition: 0.1s;
    display: flex;

    .select-container {
        margin: 0 0 0 12px;

        select {
            margin-right: 0;
        }
    }

    &:hover {
        background: $blue2;
        color: $white;
        transition: 0.1s;
    }
}

button.viewlogies-button {
    @include viewlogies-button;
}

button.viewlogies-button.pill-button {
    padding: 2px 8px;
    font-family: 'Chivo', sans-serif;
    text-transform: uppercase;
    outline: 0;
    border: 2px solid $green;
    border-radius: 12px;
    color: $green;
    font-size: 12px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    transition: 0.1s;
    display: flex;

    .select-container {
        margin: 0 0 0 12px;

        select {
            margin-right: 0;
        }
    }

    &:hover {
        background: $green;
        color: $white;
        transition: 0.1s;
    }
}

@mixin inline-button {
    padding: 0;
    border: none;
    text-transform: none;
    font-size: 14px;
    font-weight: bold;

    &:hover {
        background: none;
    }
}

button.viewlogies-button.inline-button {
    @include inline-button;
    color: $blue2;
    text-decoration: underline;
}
button.viewlogies-button.link-button {
    @include inline-button;
    color: #0000ee;
    text-decoration: underline;
    font-weight: normal;
    background: none;
}

.viewlogies-button {
    .button-content {
        height: 100%;
        margin: auto 0;
    }
}
