@import 'settings.scss';

.portal-login-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $grey2;

    text-align: center;

    .header {
        background-color: unset;
        text-align: center;
    }

    .portal-login {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
