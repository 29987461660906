@import 'settings';


.ReactModal__Content {
    @media (min-width: $mobileBreakpoint) {
        inset: 24px !important; // override default 0 inset used for mobile
    }
}

.modal {
    background-color: $teal;
    color: $white;
}