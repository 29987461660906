@import 'settings.scss';

.header {
    width: 100%;
    text-align: left;

    a {
        color: inherit;
        text-decoration: inherit;
    }

    h2 {
        font-size: 24px;
        margin: 6px 0 42px 0;
    }
    
    @media (min-width: $mobileBreakpoint) {
        text-align: center;
        margin: 22px 0;
        position: relative;
        height: auto;
    }
}

.header-black {
    background-color: $white;
    opacity: .8;
}

.header-white {
    color: $white;
    font-weight: bold;
}
