@import 'settings.scss';

.multi-input-container.single-field {
    display: flex;

    .multi-input-field {
        flex-grow: 1;

        .input {
            width: 100%;
            //width: calc(100% - 42px);
        }
    }
}

.multi-input-container {
    position: relative;
    margin-bottom: 18px;

    .multi-input-button {
        margin: 8px 0 0 0;
    }

    .button-icon {
        margin-left: 6px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 50%;
        font-size: 18px;
        transition: color 0.15s;
        transition: background-color 0.15s ease-in;
        -webkit-transition: color 0.15s;
        -webkit-transition: background-color 0.15s ease-in;
    }
    .button-icon:before {
        padding-left: 8.5px;
    }
    .button-icon:hover {
        cursor: pointer;
        color: $grey4;
        background-color: rgba(255, 255, 255, .8);
        transition: color 0.15s;
        transition: background-color 0.15s ease-in;
        -webkit-transition: color 0.15s;
        -webkit-transition: background-color 0.15s ease-in;
    }
}
