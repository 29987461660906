@import 'settings.scss';

.dashboard-container {
    .subheader-item.streams-info-container {
        display: flex;
        justify-content: center;
        color: $teal;
        padding: 20px;

        .streams-info {
            text-align: center;
            margin: auto 0;

            h2 {
                font-size: 46px;
                margin: 0;
            }
        }
    }
}