// store global variables here

$mobileBreakpoint: 768px;

$black: #000;
$white: #fff;
$teal: #16394e;
$red: #e93f33;
$green: #306f5c;
$yellow: #f7d308;

// viewlogies accent color
$blue1: #e1e7e9;
$blue2: #253746;

$grey1: #fafafa;
$grey2: #e1e1e1;
$grey3: #afafaf;
$grey4: #7d7d7d;
$grey5: #646464;
$grey6: #323232;
$grey7: #191919;

// fonts
$chivo: 'Chivo', san serif;
$comfortaa: 'Comfortaa', sans-serif;

.close-icon:hover {
    cursor: pointer;
}
